@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Young+Serif&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Gabarito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');

* {
    /* font-family: 'Quicksand', sans-serif; */
    /* font-family: 'Josefin Sans', sans-serif; */
    /* font-family: 'Young Serif', serif; */
    font-family: 'Gabarito', cursive;

    scroll-behavior: smooth;
}
/* General Container Styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  word-wrap: break-word;
}

th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

/* Responsive Table for Mobile */
@media (max-width: 768px) {
  table, th, td {
      display: block;
      width: 100%;
  }

  th, td {
      box-sizing: border-box;
      padding: 8px;
      text-align: left;
  }
}

/* Image Styling */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

/* Paragraph Styling */
p {
  line-height: 1.6;
  margin: 10px 0;
}

/* Scrollable Table Wrapper */
.scrollable-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.skeleton-loader {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.skeleton-title {
  width: 70%;
  height: 16px;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skeleton-line {
  width: 100%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
}


/* From Uiverse.io by Chriskoziol */ 
.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 100px;
  height: 100px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #299fff;
  animation: tri-spinner 1s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: tri-spinner 2s infinite;
}

.spinner::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes tri-spinner {
  100% {
    transform: rotate(1turn);
  }
}

.loader {
  color: #4a4a4a;
  font-family: "Poppins",sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  padding: 10px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 8px;
}

.words {
  overflow: hidden;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #299fff;
  animation: cycle-words 5s infinite;
}

@keyframes cycle-words {
  10% {
    -webkit-transform: translateY(-105%);
    transform: translateY(-105%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-205%);
    transform: translateY(-205%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-305%);
    transform: translateY(-305%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-405%);
    transform: translateY(-405%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}
.category-container {
  display: flex;
  justify-content: center; /* Centers the categories horizontally */
  align-items: center;
  flex-wrap: wrap; /* Ensures the buttons wrap if they overflow */
  /* border-bottom: 2px solid #e5e5e5; */
  padding: 10px 0;
  margin-bottom: 16px;
  font-weight: 600;
}

.category-button {
  margin-right: 16px;
  padding: 10px 20px;
  background-color: white;
  border-radius: 0;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.category-button:hover {
  background-color: #f0f0f0; /* Light gray for hover effect */
}

.category-button.active {
  background-color: #3F83F8; /* Brown color for the active category */
  color: white;
  border-bottom: 3px solid #3F83F8; /* Brown border under active category */
}

/* src/styles/ScrollToTopButton.css */
.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(42, 102, 230);
  align-items: center;
}

.button:hover .svgIcon {
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  font-size: 0px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  transition-duration: 0.3s;
}


.buttonapply {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
}

.icon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.buttonapply:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.buttonapply:hover .icon {
  transform: translate(4px);
}

.buttonapply:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.buttonapply::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.pagination li{
  margin: 0 5px;
}
.pagination a{
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-decoration: none;
  color: #000;
}
.pagination a:hover{
  background: #eee;
}
.pagination li.activerPagination{
  background: rgb(8, 163, 190);
  border-radius: 4px;
  color: #eee;
}
.active-button{
  color: rgb(52, 144, 219);
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Number of lines to show */
    /* Ensure the container has fixed height for line-clamp to work */
    height: 50px; /* Adjust as needed */
}

/* Add this CSS to your stylesheet (e.g., App.css) */
.ag-courses-item_link:hover >  .ag-courses-item_title {
    color: #FFF;
}
.ag-courses_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  
    padding: 50px 0;
  }
  .ag-courses_item {
    -ms-flex-preferred-size: calc(33.33333% - 30px);
    flex-basis: calc(33.33333% - 30px);
  
    margin: 0 15px 30px;
  
    overflow: hidden;
  
    border-radius: 28px;
  }

  .ag-courses-item_link {
    display: block;
    padding: 30px 20px;
  
    overflow: hidden;
  
    position: relative;
  }
  .ag-courses-item_link:hover,
  .ag-courses-item_link:hover .ag-courses-item_date {
    text-decoration: none;
    color: #000;
  }
  .ag-courses-item_link:hover .ag-courses-item_bg {
    -webkit-transform: scale(10);
    -ms-transform: scale(10);
    transform: scale(10);
  }
  .ag-courses-item_title {
    min-height: 87px;
    margin: 0 0 25px;
  
    overflow: hidden;
  
    font-weight: bold;
    color: #000;
  
    z-index: 2;
    position: relative;
  }
  .ag-courses-item_date-box {
    font-size: 18px;
    color: #FFF;
  
    z-index: 2;
    position: relative;
  }
  .ag-courses-item_date {
    font-weight: bold;
    color: #3b82f6;
  
    -webkit-transition: color .5s ease;
    -o-transition: color .5s ease;
    transition: color .5s ease
  }
  .ag-courses-item_bg {
    height: 128px;
    width: 128px;
    background-color: #3b82f6;
  
    z-index: 1;
    position: absolute;
    top: -75px;
    right: -75px;
  
    border-radius: 50%;
  
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    
    .ag-courses_item {
        
        margin: 0 0px 30px;

      }
  }